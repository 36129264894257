//import modules
import * as React from "react"

//import files
import '../styles/header.css'

const Header = () => (
    <header className='d'>
        bibimbap
    </header>
)

export default Header 