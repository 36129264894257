import * as React from "react"
import Layout from '../components/layout.js'
import Header from '../components/header.js'

// markup
const IndexPage = () => {
  return (
    <main>
      <Header />
    </main>
  )
}

export default IndexPage